import { createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_modal = _resolveComponent("c-modal")!

  return (_openBlock(), _createBlock(_component_c_modal, {
    id: "v-appointment-confirm",
    show: _ctx.show,
    "onClick:close": _ctx.handleClose,
    "onClick:background": _ctx.handleClose
  }, {
    title: _withCtx(() => [
      _createTextVNode("Confirmar reagendamento?")
    ]),
    content: _withCtx(() => [
      _createTextVNode("Reagendar para " + _toDisplayString(_ctx.$route.query.date) + " as " + _toDisplayString(_ctx.$route.query.hora) + ".", 1)
    ]),
    footer: _withCtx(() => [
      _createElementVNode("button", {
        class: "button is-danger is-outlined",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClose && _ctx.handleClose(...args)))
      }, "Cancelar"),
      _createElementVNode("button", {
        class: _normalizeClass(['button is-success', { 'is-loading': _ctx.loading.submit }]),
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleConfirm && _ctx.handleConfirm(...args)))
      }, "Confirmar", 2)
    ]),
    _: 1
  }, 8, ["show", "onClick:close", "onClick:background"]))
}