
import { defineComponent, reactive, ref } from "vue";
import { useStore } from "vuex";
import { useHead } from "@vueuse/head";
import { useRoute, useRouter } from "vue-router";

import CModal from "@/components/modal.vue";

const VAppointmentConfirm = defineComponent({
  name: "VAppointmentConfirm",
  components: { CModal },
  setup() {
    useHead({ title: "Confirmar reagendamento | obmed" });

    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const _id = Number(route.params._id);
    const cd_horario_novo = Number(route.query.hr);

    const show = ref(true);
    const loading = reactive({ submit: false });

    function handleClose() {
      show.value = false;
      router.back();
    }

    async function handleConfirm() {
      loading.submit = true;
      const response = await store.dispatch("rescheduleAppointment", { _id, cd_horario_novo });
      loading.submit = false;

      if (response?.status === 200 || response?.status === 201) {
        store.commit("setToast", [
          { summary: "Reagendamento realizado com sucesso!", severity: "success", life: 6000 },
        ]);

        router.replace({ name: "appointment" });
      }
    }

    return { show, loading, handleClose, handleConfirm };
  },
});

export default VAppointmentConfirm;
